import React from "react"

import "../../styles/component/pulsating-circle.scss"

const PulsatingRing = (props) => (
  <>
    <div className={`pulsating-circle ${props.className??""}`}></div>
  </>
)

export default PulsatingRing

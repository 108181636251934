import React, { useState } from "react"

import "../../styles/component/video-player.scss"

import WistiaVideoOnclickEmbed from "./wistia-video-onclick-embed"
import { Modal, Container } from "react-bootstrap"
import Close from "./../../assets/images/close-white.png"

const YoutubePlayer = props => {
  const [show, setShow] = useState(false)

  const handleClose = () => {
    setShow(false)
  }
  const handleShow = () => {
    setShow(true)
  }

  return (
    <>
      {props.fullscreenCover ?
       <div
       className="video-player fullScreenImg"
       onClick={handleShow}
       role="button"
       tabIndex={0}
       style={{backgroundImage:`url(${props?.videoScreenshot?.url})`}}
     >
       <WistiaVideoOnclickEmbed
         red={props.red}
         videoTitle={props.title}
         className={props.className}
       />
     </div>
      : <div
        className="video-player"
        onClick={handleShow}
        role="button"
        tabIndex={0}
      >
        <WistiaVideoOnclickEmbed
          videoScreenshot={props?.videoScreenshot?.url}
          red={props.red}
        />
      </div>}

      <Modal
        className="yt"
        show={show}
        onHide={() => setShow(false)}
        centered
        dialogClassName="modal-80w"
        aria-labelledby="example-custom-modal-styling-title"
      >
        <img src={Close} alt="close" className="close-icn" onClick={handleClose}/>
        <Container className="yt-vdo">
          <iframe
            className="video"
            src={`${props.videoURL}?rel=0&autoplay=1`}
            frameborder="0"
            allowfullscreen = "allowfullscreen"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          ></iframe>
          </Container>
      </Modal>
    </>
  )
}

export default YoutubePlayer
